.slick-arrow.slick-prev.custom-arrow.prev-arrow::before{
    /*내장 왼쪽 버튼 스타일 숨기기*/
    display:none;
}
.slick-arrow.slick-next.custom-arrow.next-arrow::before{
    /*내장 오른쪽 버튼 스타일 숨기기*/
    display:none;
}

.custom-arrow {
    /*position: absolute;*/
    /*transform: translateY(-50%);*/
    /*width: 30px; !* 화살표 너비 *!*/

    color: #ccc; /* 화살표 색상 */
    font-size: 3rem; /* 화살표 크기 */
    line-height: 100px; /* 세로 중앙 정렬 */
    cursor: pointer;
    z-index: 1;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    /*transition: color 0.3s ease;*/
    height: 100%;
}

.next-arrow{
    /*padding-right: 2%;*/
    /*margin-right:-8%;*/
    right: -30px;


}
.prev-arrow{
    /*padding-left:2%;*/
    /*margin-left:-8%;*/

    left: 0px;


}

.custom-arrow:hover {
    color: #333; /* hover 시 색상 변경 */

}

.modal-dialog.modal-dialog-centered{
@media(max-width:480px){
    width:100%;
    margin:0px;
    display:flex;
    justify-content: center;
}
}