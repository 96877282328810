.imageSlide {
    margin-bottom: 5px;
    /* margin-top: -5px; */
}

.dots_custom li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
}

.dots_custom li button {
    border: none;
    background: #d1d1d1;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    padding: 0;
}

.dots_custom li.slick-active button {
    background-color: #08c1ce;
}

.slick-arrow {
    z-index: 10;
    width: 49px;
    height: 49px;
    /* background: rgba(0, 0, 0, 0.2); */
    border-radius: 50%;

    &::before {
        /* font-family: "Line Awesome Free"; */
        font-weight: 900;
        font-size: 49px;
        transition: all 0.5s;
    }
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 30px;
}

@media (max-width: 480px) {
    .slick-arrow {
        z-index: -10;
    }
}
