/* RemoteNode.css */

.remote-node-container {
    width: 140px;
}

.remote-node-image {
    width: 100%;
    height: 140px;
    background-color: white;
}

.remote-node-name {
    font-size: 13px;
    font-weight: bold;
}

.remote-node-price {
    display: flex;
    justify-content: end;
    font-size: 15px;
    font-weight: bold;
}

@media (max-width: 760px) {
    .remote-node-container {
        width: 60px;
    }

    .remote-node-image {
        width: 100%;
        height: 60px;
    }

    .remote-node-name {
        display: none;
    }
}

@media (max-width: 480px) {
    .remote-node-container {
        width: 50px;
    }

    .remote-node-image {
        width: 100%;
        height: 50px;
    }

    .remote-node-price {
        display: none;
    }
}

@media (max-width: 375px) {
    .remote-node-container {
        width: 40px;
    }

    .remote-node-image {
        width: 100%;
        height: 40px;
    }
}
