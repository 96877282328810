/* 기존 Nav 스타일 */
.nav-container {
    position: relative;
    z-index: 10;
}

/* 스크롤 시 상단에 고정될 때 스타일 */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1295px;
    background-color: white; /* 필요 시 배경색 추가 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
    z-index: 1000; /* 필요에 따라 z-index 조정 */
}

@media (max-width: 1300px) {
    .sticky {
        max-width: 1115px;
    }
}

@media (max-width: 1200px) {
    .sticky {
        max-width: 935px;
    }
}

@media (max-width: 992px) {
    .sticky {
        max-width: 695px;
    }
}

@media (max-width: 768px) {
    .sticky {
        max-width: 515px;
    }
}


#sticky-nav {
    .nav{
        background-color:#fafafa;
    }
}

