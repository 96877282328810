.item {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
}

.item:hover {
    transform: scale(1.05);
}

.image {
    position: relative; /* 이미지 컨테이너를 기준으로 버튼을 배치할 수 있도록 설정 */
    width: auto;
    height: auto;
    margin-bottom: 25px;
}

.image img {
    height: 217px;
    width: auto;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 480px) {
    .img-container {
        height: auto;
    }

    .image {
        width: 100%;
        height: 217px;
        text-align: center;
        margin-bottom: 25px;
    }
}

.img-container {
    width: 100%;
    height: 217px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* 장바구니 버튼 위치 조정을 위한 상대적 위치 */
}

.item-img {
    width: 100%;
    height: auto;
}

.info {
    height: 120px;
    left: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    opacity: 0.9;
    transition: transform 0.5s ease;
    transform: translateY(calc(100% - 140px));
    margin-bottom: -20px;
}

/* .item:hover .info {
  transform: translateY(0);
} */

.content {
    padding: 0cap 10px 0px 10px;
}

.title,
.price {
    visibility: visible;
}

.title {
    font-size: 12px;
    margin-bottom: 5px;
    white-space: normal;
    overflow: visible;
}

.price {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.discount {
    color: red;
    font-weight: bold;
}

.original {
    font-size: 12px;
    text-decoration-line: line-through;
}

.additional-info {
    /* padding: 10px; */
    padding-top: 0px;
    opacity: 0;
    transition: opacity 0.5s ease;
}

@media (max-width: 480px) {
    .additional-info {
        opacity: 1;
    }
}

.item:hover .additional-info {
    opacity: 1;
}

.rating {
    text-align: center;
    margin-bottom: -10px;
}

/* 장바구니 버튼의 새로운 스타일 */
.nodeBtn {
    position: absolute; /* 이미지 안에 위치시키기 위해 절대 위치로 설정 */
    bottom: 10px; /* 이미지의 하단에서 10px 떨어진 위치 */
    right: 10px; /* 이미지의 오른쪽에서 10px 떨어진 위치 */
    width: 40px; /* 버튼의 원형 크기 */
    height: 40px;
    background-color: #357ddc; /* 버튼 배경색을 검정색으로 설정 */
    border-radius: 50%; /* 버튼을 원형으로 만듦 */
    border: none; /* 버튼 테두리 제거 */
    display: flex;
    opacity: 0.7;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.35s ease,
    box-shadow 0.35s ease;
}

.nodeBtn img {
    width: 25px; /* 아이콘 크기 */
    height: 25px;
    z-index: 10; /* 아이콘이 이미지 위에 나타나도록 설정 */
}

.nodeBtn:hover {
    transform: scale(1.1); /* 호버 시 버튼이 약간 확대됨 */
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2); /* 호버 시 그림자 효과 추가 */
}

.tag {
    border: 1px solid gray;
    margin: 4px 6px 2px 0;
    padding: 0 3px;
    font-size: 13px;
    font-weight: bold;
}

.sale-tag {
    background-color: red;
    color: white;
}

.new-tag {
    color: green;
}

.hot-tag {
    color: red;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
}

.rating-text {
    font-size: 10px;
}

.icon-small {
    width: 15px;
}

.icon-large {
    width: 20px;
}

.original-price {
    text-decoration: line-through;
    font-weight: normal;
    font-size: 13px;
    padding-left: 5px;
}

.discount-percentage {
    font-weight: bold;
    font-size: 18px;
    padding-left: 5px;
    color: red;
}
