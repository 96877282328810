/* 기존 애니메이션 스타일 */
.slide-top {
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
    0% {
        transform: translateY(175px);
    }
    100% {
        transform: translateY(-40px);
    }
}

.slide-down {
    animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-down {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(175px);
    }
}

@media (max-width: 1150px) {
    @keyframes slide-top {
        0% {
            transform: translateY(175px); /* 더 크게 이동 */
        }
        100% {
            transform: translateY(-220px); /* 더 크게 이동 */
        }
    }

    @keyframes slide-down {
        0% {
            transform: translateY(-220px); /* 더 크게 이동 */
        }
        100% {
            transform: translateY(175px); /* 더 크게 이동 */
        }
    }
}

@media (max-width: 480px) {
    @keyframes slide-top {
        0% {
            transform: translateY(175px);
        }
        100% {
            transform: translateY(-120px);
        }
    }

    @keyframes slide-down {
        0% {
            transform: translateY(-120px);
        }
        100% {
            transform: translateY(175px);
        }
    }
}

.rotate-center {
    animation: rotate-center 0.4s ease-out both;
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
}

.rotate-center-reverse {
    animation: rotate-center-reverse 0.4s ease-out both;
}

@keyframes rotate-center-reverse {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.remote-button {
    position: fixed;
    bottom: 205px;
    right: 5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.remote-img {
    width: 120%;
}

.remote-container {
    position: fixed;
    bottom: -320px;
    width: 100%;
    background-color: white;
    border-top: 4px solid #498ddd;
    height: 550px;
    z-index: 1;
}

.remote-cart {
    margin-left: 20%;
    margin-right: 20%;
    gap: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remote-text {
    font-size: 20px;
    margin-top: 10px;
    color: #004393;
    text-align: center;
    cursor: pointer;
}

/* 반응형 스타일 추가 */
@media (max-width: 1150px) {
    .remote-button {
        width: 48px;
        height: 48px;
    }
}

@media (max-width: 992px) {
    .remote-button {
        width: 46px;
        height: 46px;
    }

    .remote-text {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .remote-button {
        width: 44px;
        height: 44px;
    }

    .remote-text {
        font-size: 19px;
    }
}

@media (max-width: 576px) {
    .remote-button {
        width: 35px;
        height: 35px;
    }

    .remote-button {
        position: fixed;
        bottom: 210px;
    }

    .remote-text {
        font-size: 19px;
    }
}
