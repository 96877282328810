.item-list-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.container {
    margin-top: 20px;
}

.item-grid {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .item-grid {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
}

@media (max-width: 480px) {
    .item-grid {
        grid-template-columns: repeat(2, minmax(120px, 2fr));
    }
}

.load-more-button-container {
    display: flex;
    justify-content: center;
}

.load-more-button {
    margin: 20px;
    width: 90%;
}

.random-items-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.item-node-wrapper {
    padding: 10px;
    box-sizing: border-box;
}
