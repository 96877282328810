@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

body {
    margin: 0;
    font-family: 'Noto Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'ONE_Mobile_Title';
}

/* 
@font-face {
  font-family: "ONE_Mobile_Title";
  font-weight: normal;
  src: url("./fonts/ONE_Mobile_Title.ttf") format("truetype");
} */
* {
    font-family: 'Noto Sans', sans-serif;
}
