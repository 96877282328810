.itemPage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.itemInfo {
    width: 50%;
    margin-left: 20px;
    text-align: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.imageItem {
    width: 50%;
}

@media (max-width: 450px) {
    .itemPage {
        flex-direction: column;
        align-items: center;
    }

    .itemInfo {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .imageItem {
        width: 100%;
    }
}

.item-title {
    font-size: 22px;
    white-space: normal;
}

.rate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.rating-text {
    font-size: 15px;
}

.review-link {
    text-decoration: none;
}

.review-count {
    margin-left: 7px;
    font-size: 13.5px;
}

.separator {
    margin: 0 7px;
    color: lightgray;
}

.order-count {
    font-size: 13.5px;
}

.price-and-share {
    padding-top: 7px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sale-price {
    font-size: 20px;
    font-weight: bold;
}

.original-price {
    font-size: 14px;
    text-decoration: line-through;
    padding-left: 7px;
}

.discount-percentage {
    font-size: 20px;
    padding-left: 7px;
    font-weight: bold;
    color: red;
}

.item-id {
    font-size: 13px;
    color: gray;
}

.item-details {
    font-size: 12px;
}

.more-options {
    padding-top: 15px;
    font-size: 16px;
}

.option-label {
    font-size: 12px;
}

.action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 15px;
}

.reviews-section {
    border: 1px solid lightgray;
    margin-top: 40px;
    padding: 11px;
    width: 100%;
    overflow-x: auto;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
