.naverLoginContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.naverIdLogin {
    display: none;
}

.naverLoginBtn {
    display: flex;
    align-items: center;
    width: 268px;
    height: 66px;
    background-color: #03c75a;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

.naverIcon {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background: url('../../img/navericon.png') no-repeat center;
    background-size: 40px;
}

.naverLoginTitle {
    margin-left: 48px;
    color: white; /* 테마 색상을 직접 적용 */
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
