/* TopNav.css */

/* Base styles */
.navbar-custom {
    font-weight: bold;
    justify-content: flex-end;
}

.navbar-custom .nav-link {
    color: black;
    font-size: 12px;
    padding: 1vh;
    font-size: 15px;
}

.nav-underline .nav-link.active {
    color: #0a58ca !important;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .navbar-custom .nav-link {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .navbar-custom .nav-link {
        font-size: 8px;
    }
}

@media (max-width: 480px) {
    .navbar-custom .nav-link {
        font-size: 8px;
    }
}
